<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('dashboard.menu.integration')} / Solways` }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Desde
                      </th>
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Hasta
                      </th>
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Cupo
                      </th>
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Release
                      </th>
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Min. noches
                      </th>
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Max. noches
                      </th>
                      <th
                        colspan="6"
                        class="text-center"
                      >
                        Ocupación
                      </th>
                      <th
                        class="text-center"
                      >
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center">
                        Adul.
                      </th>
                      <th class="text-center">
                        Teen
                      </th>
                      <th class="text-center">
                        Mnr.
                      </th>
                      <th class="text-center">
                        Inf.
                      </th>
                      <th class="text-center">
                        Estado
                      </th>
                      <th class="text-center">
                        Precio
                      </th>
                      <th class="text-center">
                        Tarifa
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(season, indS) in tarifariosHotels"
                      :key="indS"
                      class="text-center"
                    >
                      <!--<td>{{ $moment(season.from).format('DD MMM Y') }}</td>
                      <td>{{ $moment(season.to).format('DD MMM Y') }}</td>-->
                      <td>{{ $moment(season.dateFrom).format('DD-MMM-Y') }}</td>
                      <td>{{ $moment(season.dateTo).format('DD-MMM-Y') }}</td>
                      <td>
                        <template v-if="!source.includes('hotetec') && !source.includes('dingus')">
                          <span
                            v-if="season.on_request"
                            style="color: red"
                          >
                            OR
                          </span>
                          <span v-else>
                            {{ season.availableQuota }}
                          </span>
                        </template>
                        <template v-if="source.includes('hotetec') || source.includes('dingus')">
                          {{ season.availableQuota }}
                        </template>
                      </td>
                      <td>
                        <template v-if="!source.includes('hotetec') && !source.includes('dingus')">
                          <span
                            v-if="season.release_na"
                            style="color: red"
                          >
                            N/A
                          </span>
                          <span v-else>
                            {{ season.release }}
                          </span>
                        </template>
                        <template v-if="source.includes('hotetec') || source.includes('dingus')">
                          {{ season.release }}
                        </template>
                      </td>
                      <td>{{ season.minimumStay }}</td>
                      <td>{{ season.maximumStay }}</td>
                      <!--<td>{{ season.checkinRestriction }}</td>
                      <td>{{ season.checkoutRestriction }}</td>-->
                      <td>
                        <span v-if="tarifarioFiltersHotels.adult === 'N/A'">
                          N/A
                        </span>
                        <span v-else>{{ season.adult }}</span>
                      </td>
                      <td>
                        <span v-if="tarifarioFiltersHotels.teen === 'N/A'">
                          N/A
                        </span>
                        <span v-else>{{ season.teen }}</span>
                      </td>
                      <td>
                        <span v-if="tarifarioFiltersHotels.children === 'N/A'">
                          N/A
                        </span>
                        <span v-else>{{ season.children }}</span>
                      </td>
                      <td>
                        <span v-if="tarifarioFiltersHotels.babies === 'N/A'">
                          N/A
                        </span>
                        <span v-else>{{ season.babies }}</span>
                      </td>
                      <td :style="season.status === 'Closed' ? 'color: red !important;' : ''">
                        <b v-if="season.status === 'Closed'">{{ season.status }}</b>
                        <span v-else>{{ season.status }}</span>
                      </td>
                      <td>
                        <span v-if="season.mnrAmount > 0 && season.mnrAmount !== season.amount">
                          <span style="color: red; text-decoration: line-through">
                            {{ season.mnrAmount | currency }}
                          </span>
                        </span>
                        <!--<span v-if="season.myrAmount > 0 && season.myrAmount > season.amount">
                          <span style="color: red; text-decoration: line-through">
                            {{ season.myrAmount | currency }}
                          </span>
                        </span>-->
                        {{ season.amount | currency }}
                      </td>
                      <td>
                        <template v-if="tarifasHotels.length > 0">
                          <span v-if="tarifasHotels.filter(e => e.code === season.code_tarifa).length > 0">
                            {{ tarifasHotels.filter(e => e.code === season.code_tarifa)[0].name }}
                          </span>
                        </template>
                        <template v-if="tarifasDirectoHotels.length > 0">
                          <span v-if="tarifasDirectoHotels.filter(e => e.code === season.code_tarifa).length > 0">
                            {{ tarifasDirectoHotels.filter(e => e.code === season.code_tarifa)[0].name }}
                          </span>
                        </template>
                      </td>
                    </tr>
                    <!--<tr
                      class="text-center"
                    >
                      <td colspan="11"></td>
                      <td>{{ seasonAll | currency }}</td>
                    </tr>-->
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'
import FileHotel from '../utils/FileHotel.vue'

export default {
  components: {
    Loading,
    // eslint-disable-next-line vue/no-unused-components
    FileHotel,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      item: {},
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      files: [],
      lote: [],
      json: null,
      isDialogVisibleDelete: false,
      sources: [],
      sourcesUser: [],
      contrates: [],
      search: {
        source: null,
        contrate_id: null,
      },
      user: {},
      ready: true,
      operadorsId: [],
      listHotel: [],
      allHotel: [],
      hotelsPactados: [],
      allRooms: [],
      allPlanes: [],
      allCategoriesTarifarios: [],
      allTarifas: [],
      tarifariosHotels: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.profile()
    if (localStorage.getItem('lote-solways-id') === null) {
      this.$router.push({ name: 'channel-simulator' })
    } else {
      this.tarifariosLote()
    }
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
    },

    async tarifariosLote() {
      this.isLoading = true
      const json = {
        id: localStorage.getItem('lote-solways-id'),
      }
      await this.axios
        .post('channel-solways/process-file', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.filterTarifarios()
          }
        })
        .finally(() => this.resetFile())
    },
    async filterTarifarios() {
      const json = {
        all: true,
      }
      this.axios
        .post('channel-solways/tarifarios', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.tarifariosHotels = response.data.data
        })
    },

    resetFile() {
      this.isLoading = false
    },
  },
}
</script>
